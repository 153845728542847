import React from 'react';
import { Box, AspectRatio, Text, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import {
  THE_BRIDE,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_TIME,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
} from '@/constants';
import { IMG_PHOTO_COVER } from '@/constants/assets';

import txt from './locales';
import useLang from '@hooks/useLang';

function YoutubeLiveSection({ ...rest }) {
  const defaultLang = useLang();
  const LANG = defaultLang;
  return (
    <Box zIndex="3" position="relative" bgColor="bgPrimary" {...rest}>
      <Box
        width="100%"
        bgImage={`url(${IMG_PHOTO_COVER})`}
        minHeight="120px"
        bgSize="110%"
        bgPosition="center"
      />
      {/* Title & Desctiption Section */}
      <Box zIndex="2" textAlign="center" position="relative" padding="32px 24px 0 24px">
        <WithAnimation>
          <AspectRatio
            maxW="560px"
            ratio={16 / 9}
            borderRadius="lg"
            boxShadow="xl"
            marginTop="-70px"
          >
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '8px' }}
            />
          </AspectRatio>
        </WithAnimation>
        {/* Live stream section */}
        <Box padding="42px 16px">
          <WithAnimation>
            <Text
              textAlign="center"
              color="mainColorText"
              dangerouslySetInnerHTML={{
                __html: `Live on <br /> ${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
          <WithAnimation>
            <Text
              textAlign="center"
              fontSize="md"
              fontFamily="serif"
              color="mainColorText"
              margin="16px 0"
            >
              {txt.desc[LANG]}
            </Text>
          </WithAnimation>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank">
              <Button
                size="sm"
                fontWeight="normal"
                color="btnMainColor"
                bgColor="bgSecondary"
                marginTop="24px"
              >
                {txt.open[LANG]}
              </Button>
            </Link>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
