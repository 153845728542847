import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Siaran Langsung', `Witness the Moment`),
  desc: get(
    `Kami memahami bahwa karena pembatasan Covid-19, tidak semua orang dapat menghadiri pernikahan kami. Namun, kehadiran Anda secara virtual melalui live streaming akan sangat berarti bagi kami.`,
    `We are pleased to announce and invite you to our wedding. We understand that due to the Covid-19 restrictions that it is not possible for everyone to attend our wedding. However, your presence virtually via live streaming will mean a lot to us.`,
  ),
  problem: get(
    `Jika Anda mengalami kendala dalam memutar video diatas, silahkan klik tombol dibawah ini:`,
    `Should you have any issues with video above, then please click on the button below instead:`,
  ),
  open: get('Buka via YouTube', 'Open via YouTube'),
};